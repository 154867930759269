

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MessageController } from '@/services/request.service'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { toPage, getSchoolInfo } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import { createPagination } from '@/constant/constant'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class Outbox extends Vue {
  private safeCount = 0
  private recipients: Array<any> = []
  private senders: Array<any> = []
  private filter: any = {
    title: null,
    sendTime: [],
  }
  private loading: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private data: Array<any> = []
  private reqId: any = 0

  private moment = moment

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'outbox'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'title',
        title: this.$t('message.title'),
        scopedSlots: { customRender: 'name' },
        ellipsis: true,
      },
      {
        key: 'content',
        title: this.$t('message.content'),
        ellipsis: true,
        scopedSlots: { customRender: 'content' },
      },
      {
        key: 'recipient',
        title: this.$t('message.recipient'),
        ellipsis: true,
        scopedSlots: { customRender: 'recipient' },
      },
      {
        key: 'sendTime',
        title: this.$t('message.sendTime'),
        ellipsis: true,
        scopedSlots: { customRender: 'sendTime' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        ellipsis: true,
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }

  private created(): void {
    this.getData()
  }

  private deleteRecord(record): void {
    MessageController.deleteFrom(record.messageMasterId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.refresh()
    })
  }

  private reset(): void {
    this.filter = {
      title: null,
      sendTime: [],
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.loading = true
    const reqId = ++this.reqId
    const { title, sendTime } = this.filter
    const condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      title: title ? title.trim() : undefined,
      startTime: sendTime[0] ? moment(sendTime[0]).valueOf() : undefined,
      endTime: sendTime[1] ? moment(sendTime[1]).valueOf() : undefined,
    }
    this.getMessageIds(condition, reqId)
    MessageController.getFromList(condition)
      .then(res => {
        if (reqId !== this.reqId) return
        this.data = res.data.items.map(item => {
          return {
            ...item,
            toMember: item.toMembers
              .map(mem =>
                mem.displayName.length ? mem.displayName : (mem.enName + ' ' + mem.name).trim()
              )
              .join(', '),
            totalNum: item.toMembers.length,
          }
        })
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.error(err))
      .finally(() => (this.loading = false))
  }

  private getMessageIds(condition, reqId): void {
    MessageController.getFromList({
      ...condition,
      pageSize: 0,
      pageCurrent: 1,
    }).then(res => {
      if (reqId !== this.reqId) return
      this.$store.commit(
        'setAllMessageIds',
        res.data.items.map(item => item.messageMasterId)
      )
    })
  }

  private inputChange = debounce(() => {
    this.getData()
  }, 1000)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private sendMessage(): void {
    this.$router.push({ name: 'sendMessage' }).catch(err => {})
  }

  private viewDetail(messageId): void {
    this.$router.push({ name: 'outDetail', params: { messageId } }).catch(err => {})
  }

  private forward(messageMasterId): void {
    this.$router.push({
      name: 'sendMessage',
      query: { messageId: messageMasterId, messageMasterId, type: 'outbox' },
    })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private recall(messageId): void {
    MessageController.withdraw(messageId).then(res => {
      if (res.data) {
        this.$message.success(this.$tc('message.recallSuccess'))
      } else {
        this.$message.error(this.$tc('message.recallFailed'))
      }
      this.refresh()
    })
  }
}
