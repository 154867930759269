





























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MessageController } from '@/services/request.service'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import FlexTooltip from '@/components/FlexTooltip.vue'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
    SvgIcon,
  },
})
export default class Inbox extends Vue {
  private recipients: Array<any> = []
  private senders: Array<any> = []
  private filter: any = {
    title: null,
    receivedTime: [],
  }
  private loading: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private data: Array<any> = []
  private reqId: any = 0

  private moment = moment

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'inbox'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'title',
        title: this.$t('message.title'),
        scopedSlots: { customRender: 'name' },
        align: 'left',
      },
      {
        key: 'content',
        title: this.$t('message.content'),
        ellipsis: true,
        scopedSlots: { customRender: 'content' },
        align: 'left',
      },
      {
        key: 'sender',
        title: this.$t('message.sender'),
        align: 'left',
        scopedSlots: { customRender: 'sender' },
      },
      {
        key: 'receivedTime',
        title: this.$t('message.receivedTime'),
        align: 'left',
        scopedSlots: { customRender: 'receivedTime' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }

  private created(): void {
    this.getData()
  }

  private deleteRecord(record): void {
    MessageController.deleteTo(record.messageId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.refresh()
    })
  }

  private forward(messageId, messageMasterId): void {
    this.$router
      .push({ name: 'sendMessage', query: { messageId, messageMasterId, type: 'inbox' } })
      .catch(err => {})
  }

  private sendMessage(): void {
    this.$router.push({ name: 'sendMessage' }).catch(err => {})
  }

  private reply(messageId, messageMasterId): void {
    this.$router.push({
      name: 'replyMessage',
      query: { messageId, messageMasterId, type: 'inbox' },
    })
  }

  private reset(): void {
    this.filter = {
      title: null,
      receivedTime: [],
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    this.data = []
    const { title, receivedTime } = this.filter
    const reqId = ++this.reqId
    const condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      title: title ? title.trim() : undefined,
      startTime: receivedTime[0] ? moment(receivedTime[0]).valueOf() : undefined,
      endTime: receivedTime[1] ? moment(receivedTime[1]).valueOf() : undefined,
    }
    this.getMessageIds(condition, reqId)
    MessageController.getToList(condition)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private getMessageIds(condition, reqId): void {
    MessageController.getToList({
      ...condition,
      pageSize: 0,
      pageCurrent: 1,
    }).then(res => {
      if (reqId !== this.reqId) return
      this.$store.commit(
        'setAllMessageIds',
        res.data.items.map(item => item.messageId)
      )
    })
  }

  private inputChange = debounce(() => {
    this.getData()
  }, 1000)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private viewDetail(messageId, readFlag): void {
    if (!readFlag) {
      MessageController.setRead(messageId)
    }
    this.$router.push({ name: 'inDetail', params: { messageId } }).catch(err => {})
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
