
















































































import { Component, Vue, Model, Prop, Watch, Emit } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import flattenDeep from 'lodash/flattenDeep'

@Component
export default class GroupTransfer extends Vue {
  @Model('cancel', { type: Boolean }) private readonly visible!: boolean
  @Prop({ default: '' }) private readonly title!: string
  @Prop({ default: () => [] }) private defaultCandidateData!: Array<any>
  @Prop({ default: () => [] }) private defaultSelectedData!: Array<any>
  private searchText = ''
  private candidateData: Array<any> = []
  private selectedData: Array<any> = []

  public get filteredCandidateData(): Array<any> {
    const data = cloneDeep(this.candidateData)
    const text = this.searchText.toLowerCase().trim()
    const newDataWithEmptyGroup = data.map(group => {
      return {
        ...group,
        children: group.children.filter(
          child => child.title.toLowerCase().indexOf(text) !== -1 && !child.selected
        ),
      }
    })
    const newData = newDataWithEmptyGroup.filter(group => group.children.length)
    return newData
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.searchText = ''
      this.selectedData = cloneDeep(this.defaultSelectedData)
      this.candidateData = cloneDeep(this.defaultCandidateData)
      const selectedIds = this.selectedData.map(item => item.id)
      let candidateData = cloneDeep(this.candidateData)
      candidateData.forEach(group => {
        group.children.forEach(child => {
          if (selectedIds.includes(child.id)) {
            child.selected = true
          }
        })
      })
      this.candidateData = cloneDeep(candidateData)
    }
  }

  private onSearch(value): void {
    this.searchText = value
  }

  private expandGroup(groupId): void {
    const index = this.candidateData.findIndex(group => group.id === groupId)
    const data = {
      ...this.candidateData[index],
      expand: !this.candidateData[index].expand,
    }
    this.$set(this.candidateData, index, data)
  }

  private selectGroup(group): void {
    const newGroup = {
      ...group,
      children: group.children.map(child => {
        return {
          ...child,
          selected: true,
        }
      }),
    }
    const index = this.candidateData.findIndex(g => g.id === group.id)
    this.$set(this.candidateData, index, newGroup)
    this.selectedData = this.selectedData.concat(group.children)
  }

  private selectItem(item, idx): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(group => {
      group.children.forEach(child => {
        if (child.id === item.id) {
          child.selected = true
        }
      })
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData.push(item)
  }

  private selectAll(): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(group => {
      group.children.forEach(child => {
        child.selected = true
      })
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData = flattenDeep(candidateData.map(item => item.children))
  }

  private removeAll(): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(group => {
      group.children.forEach(child => {
        child.selected = false
      })
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData = []
  }

  private removeItem(id, index): void {
    let candidateData = cloneDeep(this.candidateData)
    candidateData.forEach(group => {
      group.children.forEach(child => {
        if (child.id === id) {
          child.selected = false
        }
      })
    })
    this.candidateData = cloneDeep(candidateData)
    this.selectedData.splice(index, 1)
  }

  @Emit('cancel')
  private closeModal(): boolean {
    return false
  }

  @Emit('confirm')
  private confirm(): Array<any> {
    this.closeModal()
    return this.selectedData
  }
}
