






import { Component, Vue, Watch } from 'vue-property-decorator'
import { getUserAuth } from '@/utils/utils'

@Component
export default class Message extends Vue {}
